import * as React from 'react';
import {TextField, Button} from "@material-ui/core";

const SubmitForm = ({onSubmitSuccess, inputs, setInputs}) => {

    const handleChange = (e) => {
        const value = e.target.value;
        setInputs(inputs => ({...inputs, [e.target.name]: value}));
    }
    const handleSubmit = () => {
        onSubmitSuccess();
    }
    return (
        <div>
            <form onSubmit={e => {
                e.preventDefault();
                handleSubmit();
            }}
                  autoComplete="off"
                  css={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: 500,
                      margin: '0 auto',
                      '.MuiButtonBase-root.Mui-disabled': {
                        pointerEvents: 'auto',
                        cursor: 'not-allowed',
                        backgroundColor: 'rgba(150, 155, 185, 0.58)',
                        color: 'white',
                      }
                  }}>
                <TextField
                    InputLabelProps={{shrink: false}}
                    label="Passwort eingeben"
                    type="password"
                    rowsMax={6}
                    autoFocus={true}
                    variant="outlined"
                    required
                    onChange={handleChange}
                    name="password"
                />
                <Button
                    disabled={!inputs['password']}
                    type="submit"
                    variant="contained"
                    color="primary"
                    css={{height: 45, marginBottom: '50px !important' }}
                >Eingeben</Button>
            </form>
        </div>
    )
}
export default SubmitForm;
import * as React from "react";
import './App.css';
import MainPage from "./pages";
import SnackbarProvider from 'react-simple-snackbar'
import SimpleReactLightbox from 'simple-react-lightbox'

function App() {
    return (
        <div className="App">
            <SimpleReactLightbox>
                <SnackbarProvider>
                    <MainPage/>
                </SnackbarProvider>
            </SimpleReactLightbox>
        </div>
    );
}

export default App;

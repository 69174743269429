import * as React from 'react';
import SubmitForm from "../components/SubmitForm";
import {css} from "@emotion/core";
import Reader from '../components/Reader';
import { useSnackbar } from 'react-simple-snackbar'
import {endPoint} from '../utils/billbee';
import {
    CircularProgress
} from '@material-ui/core';

const MainPage = () => {
    const [showLogin, setShowLogin] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [loginInputs, setLoginInputs] = React.useState({});
    const [openSnackbar, closeSnackbar] = useSnackbar({
        position: 'top-center',
        style: {
            backgroundColor: 'red',
            color: '#FFF',
        }
    });

    React.useEffect(() => {
        onSubmitSuccess(true);
    }, []);

    const onSubmitSuccess = async (onLoad = false) => {
        closeSnackbar();
        try {
            const request = await window.fetch(endPoint + '/validate.php', {
                headers: {
                    Authorization: onLoad ? (localStorage.getItem('password') || '') : loginInputs['password'],
                }
            });
            if(request.status !== 200){
                throw new Error('Incorrect Password');
            }
            !onLoad && localStorage.setItem('password', loginInputs['password']);
            setShowLogin(true);
        }
        catch (e) {
            !onLoad && openSnackbar('Das Passwort ist inkorrekt', 2000);
        }
        setIsLoading(false);
    }
    return (<div css={{ paddingTop: !showLogin ? 0 : 30, padding: 15 }}>
        <h3 css={css`
            color: orange;
            margin: 0;
            padding: 30px;
            font-size: 30px;
            display: ${!showLogin ? 'block' : 'none'}
        `}>Erfüllungsstatus</h3>
        {
            !isLoading &&
            <React.Fragment>
                {
                    !showLogin ?
                        <SubmitForm onSubmitSuccess={onSubmitSuccess} setInputs={setLoginInputs}
                                    inputs={loginInputs}/> :
                        <Reader openSnackbar={openSnackbar}/>
                }
            </React.Fragment>
        }
        {
            isLoading &&
                <CircularProgress/>
        }
    </div>)
}
export default MainPage;
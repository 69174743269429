import * as React from 'react';
import {findTrackingID, changeOrderStatus, uploadImage} from '../utils/billbee';
import {Button, TextField} from "@material-ui/core";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    CircularProgress,
} from "@material-ui/core";
import {
    FileCopy,
    CameraAlt
} from "@material-ui/icons";
import {isMobile} from 'react-device-detect';
import Quagga from "quagga";
import {SRLWrapper} from "simple-react-lightbox";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {useSnackbar} from "react-simple-snackbar";
import Webcam from "react-webcam";

const PACKED_ORDER_STATE = 7;
const DEMAND_NOTE_ORDER_STATE = 12;

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
const Reader = ({openSnackbar}) => {
    const [scanResult, setScanResult] = React.useState('');
    const [enterPressed, setEnterPressed] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [trackingData, setTrackingData] = React.useState({});
    const [error, setError] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState('');
    const barcodeInput = React.useRef(null);
    const [isSpacePress, setIsSpacePressed] = React.useState(false);

    const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar({
        position: 'top-center',
        style: {
            backgroundColor: '#28a745',
            color: '#FFF',
        }
    });

    const barCodeImageUploaded = (e) => {
        if (e.target.files && e.target.files.length) {
            Quagga.decodeSingle({...quaggaConfig, src: URL.createObjectURL(e.target.files[0])}, function (result) {
                if (result && result.codeResult) {
                    console.log(result.codeResult.code);
                    setScanResult(result.codeResult.code);
                    handleEnter();
                } else {
                    console.log('failed');
                    openSnackbar('Barcode nicht gefunden: Bitte erneut scannen');
                }

            });
        }
    }

    const handleEnter = async () => {
        setError('');
        setSuccessMessage('');
        setIsSpacePressed(false);
        if (scanResult && (scanResult.includes('`') || scanResult.includes('+'))) {
            openSnackbar('Bitte scannen Sie die richtige Tracking-Nummer erneut');
            setScanResult('');
            return;
        }
        setEnterPressed(true);
        try {
            setIsLoading(true);
            const trackingData = await findTrackingID(scanResult);
            console.log(trackingData);
            if (trackingData.state === PACKED_ORDER_STATE) {
                openSnackbar('Warnung: Bestellung bereits gepackt');
                setError('Warnung: Bestellung bereits gepackt');
            }
            setTrackingData(trackingData);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setTrackingData({});
            setError('');
            openSnackbar('Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Support ' + e);
        }
        setScanResult('');
    }

    const changeStatus = async () => {
        if (!trackingData || Object.keys(trackingData).length === 0) {
            openSnackbar('Bitte scannen Sie zuerst eine Bestellung');
            return;
        }
        setIsSpacePressed(true);
        try {
            const orderStatus = await changeOrderStatus(trackingData['order_id'], PACKED_ORDER_STATE);
            orderStatus === 200 && openSuccessSnackbar('Der Auftragsstatus wurde erfolgreich in "Gepackt" geändert');
            let imageSRC = capture();
            if(!imageSRC){
                await sleep(1000);
                imageSRC = capture();
                if(!imageSRC){
                    await sleep(2000);
                    imageSRC = capture();
                    if(!imageSRC){
                        openSnackbar('Foto konnte nicht aufgenommen werden, Versuchen Sie es erneut mit Berechtigungen.');
                        setIsSpacePressed(false);
                        return;
                    }
                }
            }
            const uploadedImage = await uploadImage(imageSRC, trackingData['order_id']);
            console.log(uploadedImage);
            console.log(imageSRC);
        } catch (e) {
            console.log(e);
            openSnackbar('Fehler beim Ändern des Auftragsstatus');
        }
        setIsSpacePressed(false);
    }

    const changeStatusToDemandNote = async () => {
        if (!trackingData || Object.keys(trackingData).length === 0) {
            openSnackbar('Bitte scannen Sie zuerst eine Bestellung');
            return;
        }
        setError('');
        setSuccessMessage('');
        try {
            const orderStatus = await changeOrderStatus(trackingData['order_id'], DEMAND_NOTE_ORDER_STATE);
            orderStatus === 200 && openSuccessSnackbar('Im Lager');
            setSuccessMessage('Im Lager');
        } catch (e) {
            console.log(e);
            openSnackbar('Fehler beim Ändern des Auftragsstatus');
        }
    }

    React.useEffect(() => {
        window.setInterval(() => {
            barcodeInput && barcodeInput.current && barcodeInput.current.focus();
        }, 2000);
    }, []);

    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            if(webcamRef && webcamRef.current) {
                const imageSrc = webcamRef.current.getScreenshot({width: 1920, height: 1080});
                return imageSrc;
            } else {
                return null;
            }
        },
        [webcamRef]
    );

    React.useEffect(() => {
        // navigator.mediaDevices.getUserMedia({ video: true }).then();
    }, []);

    return (
        <div css={{
            paddingBottom: 50,
            overflow: 'auto',
            '@media(max-width: 769px)': {
                margin: 15,
            },
            fontSize: 16,
            '@media(min-width: 1200px)': {
                '.MuiTableCell-body': {
                    fontSize: '1.2vw',
                }
            }
        }}>
            {
                isSpacePress &&
                <Webcam
                    screenshotQuality={1}
                    audio={false}
                    ref={webcamRef}
                    videoConstraints={{
                        width: 1920,
                        height: 1080
                    }}
                    screenshotFormat="image/jpeg"
                    css={{ position: 'absolute', left: -99999 }}
                />
            }
            <div css={{
                position: 'absolute',
                right: 5,
                top: 2,
                fontSize: 14,
            }}>
                <a css={{color: '#FFF'}} href="#" onClick={() => {
                    localStorage.setItem('password', '');
                    window.location.reload();
                }}>Logout</a>
            </div>
            <div css={{display: 'flex', justifyContent: 'center'}}>
                <TextField
                    InputLabelProps={{shrink: false}}
                    label="Mit Barcode oder Typ scannen"
                    type="text"
                    inputRef={barcodeInput}
                    autoFocus={true}
                    variant="outlined"
                    css={{
                        textAlign: 'center',
                        maxWidth: 500,
                    }}
                    value={scanResult}
                    onKeyDown={event => {
                        event.key === 'Enter' && handleEnter();
                        event.key === 'u' && changeStatus();
                        event.key === 't' && changeStatusToDemandNote();
                    }}
                    onChange={e => setScanResult(e.target.value.replace('u', '').replace('t', ''))}
                    name="barcode"
                />
                {
                    isMobile && <div css={{
                        marginLeft: 10,
                        marginTop: 14,
                    }}>
                        <CameraAlt
                            css={{
                                fontSize: '30px !important'
                            }}
                            onClick={() => {
                                document.getElementById('file-input').click();
                            }}
                        />
                        <input
                            type="file"
                            accept="image/*;capture=camera"
                            capture="camera"
                            css={{display: 'none'}}
                            id="file-input"
                            onChange={barCodeImageUploaded}
                        />
                    </div>
                }
            </div>
            {
                !isLoading && Object.keys(trackingData).length > 0 &&
                <div css={{
                    '.MuiTableCell-head': {
                        fontWeight: 'bold',
                    }
                }}>
                    {
                        error && <div css={{color: 'red', marginTop: -10, fontSize: 18, marginBottom: 10}}>{error}</div>
                    }
                    {
                        successMessage && <div css={{color: '#28a745', fontSize: 18, marginTop: -10, marginBottom: 10}}>{successMessage}</div>
                    }
                    <TableContainer component={Paper} css={{background: 'none !important'}}>
                        <Table size="small" css={{
                            width: '100% !important',
                            margin: '0 auto',
                            border: '1px solid rgba(224, 224, 224, 1)',
                            '.MuiTableCell-body, .MuiTableCell-head': {
                                color: '#FFF',
                            },
                            '.MuiTableCell-root': {
                                textAlign: 'center',
                                border: '1px solid rgba(224, 224, 224, 1)',
                            }
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th">
                                        Shop
                                    </TableCell>
                                    <TableCell component="th">
                                        Versandname
                                    </TableCell>
                                    <TableCell component="th">
                                        Lieferanschrift
                                    </TableCell>
                                    {
                                        (trackingData?.seller_comment || trackingData?.comments.length > 0) &&
                                        <TableCell>
                                            Bemerkungen
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {trackingData?.shop_name || <span css={{color: 'red'}}>N/A</span>}
                                        {
                                            trackingData?.shop_name &&
                                            <CopyToClipboard text={trackingData?.shop_name}>
                                                <FileCopy
                                                    css={{
                                                        cursor: 'pointer',
                                                        marginLeft: 10,
                                                        fontSize: '14px !important',
                                                        verticalAlign: 'middle'
                                                    }}
                                                />
                                            </CopyToClipboard>
                                        }
                                    </TableCell>
                                    <TableCell>{trackingData?.shipping_name || 'N/A'}</TableCell>
                                    <TableCell>{trackingData?.shipping_address || 'N/A'}</TableCell>
                                    {
                                        (trackingData?.seller_comment || trackingData?.comments.length > 0) &&
                                        <TableCell>
                                            <div css={{color: 'red'}}>
                                                {
                                                    trackingData?.seller_comment &&
                                                    <div>Verkäufer: {trackingData?.seller_comment}</div>
                                                }
                                                {
                                                    trackingData?.comments?.map(comment => {
                                                        return (
                                                            <div>{comment.FromCustomer ? 'Kunde' : comment.Name}: {comment.Text}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <SRLWrapper>
                        <TableContainer component={Paper} css={{marginTop: 20, background: 'none !important'}}>
                            <Table size="small" css={{
                                width: '100% !important',
                                margin: '0 auto',
                                border: '1px solid rgba(224, 224, 224, 1)',
                                '.MuiTableCell-body, .MuiTableCell-head': {
                                    color: '#FFF',
                                },
                                '.MuiTableCell-root': {
                                    textAlign: 'center',
                                    border: '1px solid rgba(224, 224, 224, 1)',
                                }
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th">
                                            SKU
                                        </TableCell>
                                        <TableCell component="th">
                                            Menge
                                        </TableCell>
                                        <TableCell component="th">
                                            Attribute
                                        </TableCell>
                                        <TableCell component="th">
                                            Bild
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        trackingData?.order_items.map((orderItem, i) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        {orderItem?.sku || 'N/A'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            orderItem?.quantity ?
                                                                <span css={{
                                                                    color: orderItem?.quantity > 1 ? 'red' : '#FFF',
                                                                    fontWeight: orderItem?.quantity > 1 ? 'bold' : 'normal',
                                                                }}>{orderItem?.quantity}</span>:
                                                                'N/A'
                                                        }
                                                    </TableCell>
                                                    <TableCell css={{minWidth: 180}}>
                                                        <div>
                                                            {
                                                                orderItem?.attributes.map(attr => {
                                                                    return (
                                                                        <div>{attr.Name}: <span css={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: '1.2em'
                                                                        }}>{attr.Value}</span></div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            orderItem?.image ? <div>
                                                                <img
                                                                    css={{cursor: 'pointer'}}
                                                                    src={orderItem.image}
                                                                    alt={orderItem.title}
                                                                    height={150}
                                                                />
                                                            </div> : 'N/A'
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </SRLWrapper>
                </div>
            }
            {
                !isLoading && enterPressed && Object.keys(trackingData).length === 0 &&
                <div>
                    Tracking-Nummer nicht gefunden
                </div>
            }
            {
                isLoading &&
                <CircularProgress/>
            }
        </div>
    )
}
const quaggaConfig = {
    inputStream: {
        size: 800
    },
    locator: {
        patchSize: "medium",
        halfSample: false
    },
    numOfWorkers: 1,
    decoder: {
        readers: [{
            format: "code_128_reader",
            config: {}
        }]
    },
    locate: true,
    src: null
};
export default Reader;

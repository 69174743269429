export const endPoint = process.env.NODE_ENV === 'development' ? 'https://cors-anywhere.herokuapp.com/https://hsdbarcodescan.de/api' : '/api';

export const findTrackingID = async (trackingID) => {
    const orderResult = await window.fetch(endPoint + '/?tracking_number=' + trackingID, {
        method: 'POST',
        headers: {
            Authorization: localStorage.getItem('password'),
        }
    });
    if(orderResult.status === 200){
        return await orderResult.json();
    }
    throw new Error(await orderResult.text());
};

export const changeOrderStatus = async (orderID, orderStatus) => {
    const orderResult = await window.fetch(`${endPoint}/order_status.php?order_id=${orderID}&order_status=${orderStatus}`, {
        method: 'POST',
        headers: {
            Authorization: localStorage.getItem('password'),
        }
    });
    if(orderResult.status === 200){
        return await orderResult.json();
    }
    throw new Error(await orderResult.text());
};

export const uploadImage = async (image, orderInvoice) => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('invoice_number', orderInvoice);
    const orderResult = await window.fetch(`${endPoint}/save_image.php`, {
        method: 'POST',
        headers: {
            Authorization: localStorage.getItem('password'),
        },
        body: formData
    });
    if(orderResult.status === 200){
        return await orderResult.text();
    }
    throw new Error(await orderResult.text());
};
